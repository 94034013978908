import React from "react";
import _ from "lodash";
import axios from "axios";

import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField
} from "@material-ui/core";
import Button from "@material-ui/core/Button";

import TableCrud from "../TableCrud";
import Loading from "../Miscellaneous/Loading";
import {getMonth} from "../../../scripts/global";
import Grid from "@material-ui/core/Grid";
import moment from 'moment';
import ReportsFiltering from "../ReportsFiltering";

class MediaBuys extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            config: null,
            email: '',
            emailsToFilter:[],
            isLoading: false,
            clientData: null,
            data: null,
            limit: 30,
            page: 0,
            apiUrl: "",
            showAPIUrlPopup: false,
            selectedDimensions: {
                startDate: moment().startOf('week').format('YYYY-MM-DD'),
                endDate: moment().format('YYYY-MM-DD'),
            },
            additionalFilters: {
                "Emails (separated by comma)": ''
            }
        };
        this.handleLookup = this.handleLookup.bind(this);
        this.updateEmailField = this.updateEmailField.bind(this);
    }

    componentDidMount() {
        axios.get(`${process.env.SERVER_ENDPOINT}/reportsConfig/lead-lookup`).then(response => {
            this.setState({
                ...this.state,
                config: response.data
            })
        })
    }

    updateEmailField(value) {
        this.setState({...this.state, email: value})
    }

    async handleLookup(selected, filters) {
        const email = filters['Emails (separated by comma)'];

        if(!email || email.length === 0) {
            this.setState({
                data: null,
                clientData: null
            })
            return;
        }
        const emailsToFilter=_.compact(email.split(','));
        this.setState({...this.state, isLoading: true});

        const params = {
            email_list: emailsToFilter, 
            startDate: selected.startDate, 
            endDate: selected.endDate,
            media_buys_ids: selected['Media Buy'],
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        };
        const {data} = await axios.post(
            process.env.SERVER_ENDPOINT + "/leadMark"
            , params);
        const clientData = await axios.post(
            process.env.SERVER_ENDPOINT + "/clientSubmission"
            , params);
        this.setState({data, clientData: clientData.data, isLoading: false,emailsToFilter});
    }

    render() {
        const {data, clientData, isLoading, email,emailsToFilter} = this.state;
        const leadMarkHeaders = [
            {
                label: "Media Buy ID",
                fieldName: "media_buy_id",
                minWidth:200
            },
            {
                label: "Name",
                fieldName: "media_buy_name",
                minWidth: 400
            },
            {
                label: "Unsubscribed",
                fieldName: "unsubscribed",
                type:"bool"
            },
            {
                label: "Date Unsubscribed",
                fieldName: "date_unsubscribed",
                type: "Date",
                minWidth: 300,
            },
            {
                label: "Unsubscribed Reason Code",
                fieldName: "unsubscribed_reason_code",
                minWidth: 300,
            },
            {label: "Unsubscribe Reason", fieldName: "unsubscribe_reason", minWidth: 300},
            {
                label: "Submission URL",
                fieldName: "submission_url",
                type: "template",
                default: "Get URL",
                minWidth: 200,
                onClick: data => {
                    this.setState({
                        showAPIUrlPopup: true,
                        apiUrl: data["submission_url"]
                    });
                }
            },
            {
                label: "Submission Date",
                fieldName: "submission_date",
                minWidth: 300,
                type: "Date"
            },
        ];

        const leadMarkheaders = _.filter(leadMarkHeaders, header => {
            const disabled_features = process.env.DISABLED_FEATURES.split(",");

            if (
                header.fieldName === "lm_cpl" &&
                disabled_features.indexOf("SA_CPL") > -1
            )
                return false;
            if (
                header.fieldName === "lm_cost" &&
                disabled_features.indexOf("SA_COST") > -1
            )
                return false;
            if (
                header.fieldName === "margin" &&
                disabled_features.indexOf("MARGIN") > -1
            )
                return false;
            if (
                header.fieldName === "publisher_name" &&
                disabled_features.indexOf("PUBLISHER") > -1
            )
                return false;
            if (
                header.fieldName === "post_back_url" &&
                disabled_features.indexOf("POST_BACK_URL") > -1
            )
                return false;

            return header;
        });

        const clientHeaders = [
            {
                label: "Media Buy ID",
                fieldName: "media_buy_id",
                minWidth:200
            },
            {
                label: "Name",
                fieldName: "media_buy_name",
                minWidth: 400
            },
            {
                label: "Unsubscribed",
                fieldName: "unsubscribed",
                type:"bool"
            },
            {
                label: "Date Unsubscribed",
                fieldName: "date_unsubscribed",
                minWidth: 300,
                type: "Date"
            },
            {
                label: "Unsubscribed Reason Code",
                fieldName: "unsubscribed_reason_code",
            },
            {label: "Unsubscribe Reason", fieldName: "unsubscribe_reason", minWidth: 300},
            {
                label: "POST BACK URL",
                fieldName: "post_back_url",
                type: "template",
                minWidth: 200,
                default: "Get URL",
                onClick: data => {
                    this.setState({
                        showAPIUrlPopup: true,
                        apiUrl: data["post_back_url"]
                    });
                }
            },
            {
                label: "API Status Code",
                fieldName: "api_status_code",
            },
            {
                label: "API Response Text",
                fieldName: "api_response_text",
            },
            {
                label: "Submission Date",
                fieldName: "date_submitted",
                minWidth: 300,
                type: "Date"
            }
        ];

        const clientheaders = _.filter(clientHeaders, header => {
            const disabled_features = process.env.DISABLED_FEATURES.split(",");

            if (
                header.fieldName === "lm_cpl" &&
                disabled_features.indexOf("SA_CPL") > -1
            )
                return false;
            if (
                header.fieldName === "lm_cost" &&
                disabled_features.indexOf("SA_COST") > -1
            )
                return false;
            if (
                header.fieldName === "margin" &&
                disabled_features.indexOf("MARGIN") > -1
            )
                return false;
            if (
                header.fieldName === "publisher_name" &&
                disabled_features.indexOf("PUBLISHER") > -1
            )
                return false;
            return header;
        });
        return (
            <div>
                {this.state.config !== null && (
                    <div style={{
                        backgroundColor: '#f4f8f9',
                        width: '100%',
                        padding: '30px 20px',
                        fontWeight: 'bolder',
                        boxShadow: '0 1px 0 rgba(0, 0, 0, 0.1)'
                    }}>
                    <h3 style={{float: 'left', marginLeft: '14px'}}>Lead Lookup</h3>
                    <ReportsFiltering
                        ref={this.filtering}
                        onSelect={(selected, filters) => this.handleLookup(selected, filters)}
                        selected={{
                            startDate: this.state.selectedDimensions.startDate,
                            endDate: this.state.selectedDimensions.endDate
                        }}
                        type="leadLookup"
                        config={this.state.config}>
                    </ReportsFiltering>
                    </div>
                )}

                <Dialog open={this.state.showAPIUrlPopup}>
                    <DialogTitle>API URL</DialogTitle>
                    <DialogContent style={{width: 500}}>
                        <TextField
                            variant="outlined"
                            multiline
                            style={{width: "100%"}}
                            rowsMax="4"
                            value={this.state.apiUrl}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button
                            color="primary"
                            onClick={() => this.setState({showAPIUrlPopup: false})}
                        >
                            OK
                        </Button>
                    </DialogActions>
                </Dialog>

                {isLoading && <Loading/>}
                {!isLoading && data && emailsToFilter.map((item, index) => {
                    return <div style={{ marginTop: 50 }}>
                        <div style={{marginLeft: "20px"}}>
                            <TableCrud
                                singularName
                                subHeading
                                hideCreate
                                headers={leadMarkheaders}
                                hideCrud
                                hideFilter
                                hideCSVDownload
                                hideEmailAction
                                hideDownloadAction
                                href="/media-planner/lookup"
                                data={data[item]}
                                idField="media_buy_id"
                                name={`Submissions to ${process.env.ORGANIZATION === 'LeadMark' ? 'LeadMark API' : 'SalesAgency/Traders Agency API'}`}
                                pagination={{
                                    page: this.state.page,
                                    limit: this.state.limit,
                                    setLimit: limit => this.setState({limit}),
                                    setPage: page => this.setState({page})
                                }}
                            />
                            <div style={{width: '100%', borderTop: '1px solid rgba(0, 0, 0, 0.1)'}}></div>

                            {process.env.ORGANIZATION !== 'Traders Agency' &&
                            <TableCrud
                                subHeading
                                hideCreate
                                headers={clientheaders}
                                hideCrud
                                hideFilter
                                hideCSVDownload
                                hideEmailAction
                                hideDownloadAction
                                href="/media-planner/lookup"
                                data={clientData[item]}
                                idField="media_buy_id"
                                name="Client API Submission"
                                pagination={{
                                    page: this.state.page,
                                    limit: this.state.limit,
                                    setLimit: limit => this.setState({ limit }),
                                    setPage: page => this.setState({ page })
                                }}
                            />
                            }
                        </div>
                    </div>
                })}
            </div>
        );
    }
}

export default MediaBuys;
