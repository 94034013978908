import React from "react";

import Layout from "../../components/layout";
import SEO from "../../components/seo";

import Grid from "@material-ui/core/Grid";

import style from "../../components/Dashboards/style";
import Leadlookup from "../../components/Dashboards/Leadlookup";
import {navigate} from "gatsby";
import {checkAuth} from "../../helpers/auth";

class Dashboard extends React.Component {
    componentDidMount() {
        checkAuth('media-planner');
    }

    render() {
        return (
            <Layout>
                <SEO title="Lead Lookup"/>
                <Grid container style={style.mainContainer}>
                    <Grid container justify="center" spacing={2}>
                        <div style={style.tabContainer}>
                            <Leadlookup/>
                        </div>
                    </Grid>
                </Grid>
            </Layout>
        );
    }
}

export default Dashboard;
